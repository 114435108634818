import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextProvider";
import { Title, WorkSection } from "../../components/Index";

function OurWork() {
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `get_work_sections?local=${localStorage.getItem("language")}`
  );
  return (
    <Container>
      {isLoading ? <Loading /> : ""}
      <Title name={content.ourWork} link="our-work" />
      <Row className=" min-h-[70vh]">
        {data?.data.data.map((e) => (
          <Col lg={3} xs={6}>
            <WorkSection
              key={e.id}
              img={fileUrl + e.section_image}
              name={e.section_name}
              description={e.section_description}
              link={`/our-work/${e.section_id}`}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
export default OurWork;
