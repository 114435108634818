import Loading from "../../Tools/Loading";
import { usePOST } from "../../Tools/APIs";
import { useContextTranslate } from "../../Translate/ContextProvider";
import {
  MdLocalPhone,
  MdLocationOn,
  MdOutlineMailOutline,
} from "react-icons/md";
import { Col, Container, Row } from "../../Tools/Grid-system";
const ContactUsCom = () => {
  const { content, dataAll } = useContextTranslate();
  const { handleChangeInput, loading, handleSubmit, error } = usePOST({});
  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(`contact_us`);
  };
  return (
    <Container>
      <div
        className={`w-[85%] max-sm:w-[90%] border-4 border-Purple rounded-[50px] py-5 bg-gray flex items-center max-lg:flex-col mx-auto shadow-xl max-lg:py-5 mb-10`}
      >
        <form className="flex flex-col gap-3 w-1/2 max-lg:w-3/4 max-lg:mx-auto max-lg:mt-5 mx-5 my-auto">
          <p className="text-center text-2xl font-semibold">
            {content.sendingM}
          </p>
          <input
            className="bg-gray-200 outline-[#643DBD] rounded-2xl py-6 px-5"
            type="text"
            required
            id="name"
            name="name"
            placeholder={content.YourName}
            onChange={handleChangeInput}
          />
          <input
            type="email"
            id="email"
            name="email"
            className="bg-gray-200 outline-[#643DBD] rounded-2xl py-6 px-5"
            placeholder={content.YourEmail}
            onChange={handleChangeInput}
          />
          <textarea
            id="short"
            type="text"
            name="message"
            required
            className="bg-gray-200 outline-none rounded-2xl h-40 pt-5 px-5"
            placeholder={content.YourMessage}
            onChange={handleChangeInput}
          />
          {loading ? <Loading /> : ""}
          <div>{error}</div>
          <div className="text-center" whileTap={{ scale: 0.7 }}>
            <button
              className="bg-gradient-to-l from-[#0D4870] to-[#02083C]
                      rounded-2xl outline-non text-white py-3 w-1/2 text-2xl"
              type="submit"
              onClick={handleFormSubmit}
            >
              {content.Submit}
            </button>
          </div>
        </form>
        <div className="w-1/2 max-lg:w-full">
          <ul className="space-y-5 w-fit mx-auto max-sm:space-y-2 font-semibold text-2xl max-sm:text-lg max-[330px]:text-sm mt-5">
            <h1 className="font-semibold text-4xl max-sm:text-3xl text-center">
              {content.GetInTouch}
            </h1>
            <Row className="justify-center items-center">
              <Col sm={2}>
                <MdOutlineMailOutline size={55} className="text-Main mx-5" />
              </Col>
              <Col sm={10}>
                <span className="m-2">{dataAll?.["الايميل"]}</span>
              </Col>
              <Col sm={2}>
                <MdLocationOn size={55} className="text-Main mx-5" />
              </Col>
              <Col sm={10}>
                <span className="m-2">{dataAll?.["العنوان"]}</span>
              </Col>
              <Col sm={2}>
                <MdLocalPhone size={55} className="text-Main mx-5" />
              </Col>
              <Col sm={10}>
                <span className="m-2">{dataAll?.["رقم الهاتف"]}</span>
              </Col>
            </Row>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default ContactUsCom;
