import { Link, useParams } from "react-router-dom";
import { fileUrl, useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextProvider";
import Pagination from "../../Tools/Pagination";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Button, Slider } from "../../components/Index";

function WorkDetails() {
  const { id } = useParams();
  const { filter } = useFilter({});
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `get_section_works_by_section_id/${id}?local=${localStorage.getItem(
      "language"
    )}&page=${filter.get("page") ? filter.get("page") : 1}`
  );
  console.log(data?.data.data.data);
  return (
    <>
      <Slider name={content.ourWork} />
      <Container>
        {isLoading ? <Loading /> : ""}
        <Pagination
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
        >
          <Container>
            <Row>
              {data?.data.data.data.map((e) => (
                <Col md={6} key={e.id}>
                  <div className="border border-Main rounded-xl py-2 px-3">
                    <img
                      src={fileUrl + e.images?.[0]}
                      alt=""
                      className="h-[170px] w-[170px] border-4 shadow-md rounded-full mx-auto"
                    />
                    <h1 className="text-center font-bold sm:text-xl text-Main my-2">
                      {e.name}
                    </h1>
                    <p className="text-lg py-1 max-sm:text-sm">
                      {e.description.substring(0, 150)} ...
                    </p>
                    <Button
                      name="read all"
                      link={`/our-work/details/${id}`}
                      className="w-[130px] mx-auto"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Pagination>
      </Container>
    </>
  );
}
export default WorkDetails;
