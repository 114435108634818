import { useParams } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Container } from "../../Tools/Grid-system";
import { BsWhatsapp } from "react-icons/bs";
import { Slider } from "../../components/Index";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useContextTranslate } from "../../Translate/ContextProvider";
// import { Player } from "video-react";

function SingleDetails() {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `get_section_works_by_section_id/${id}?local=${localStorage.getItem(
      "language"
    )}`
  );
  console.log(data?.data.data.data.map((e) => e.name));
  return (
    <>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className="mySwiper h-[70vh] overflow-hidden"
        style={{ direction: "ltr" }}
      >
        {data?.data.data.data.map((e) =>
          e.images.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={fileUrl + image} alt="" className="w-full" />
            </SwiperSlide>
          ))
        )}
      </Swiper>
      <Container>
        {isLoading ? <Loading /> : ""}
        <h1 className="text-3xl text-center font-bold text-Main mt-4 mb-2">
          {data?.data.data.data.map((e) => e.name)}
        </h1>
        <p className="text-lg font-sans leading-8 my-5">
          {data?.data.data.data.map((e) => e.description)}
        </p>
        <video controls className="w-full h-[350px] shadow-xl mt-7 border-t-2">
          <source src={data?.data.data.data.map((e) => fileUrl + e.video)} />
        </video>
        <a
          href={`whatsapp://send?phone=${data?.data.data.data.map(
            (e) => e.whatsapp
          )}`}
          target="_blank"
        >
          <div className="border border-Secondary flex items-center justify-center gap-5 mt-5 w-fit mx-auto px-5 py-3 rounded-lg">
            <p className="text-[#5BC61E] text-xl font-bold">{content.contact}</p>
            <BsWhatsapp size={30} color="#5BC61E" className="cursor-pointer" />
          </div>
        </a>
      </Container>
    </>
  );
}
export default SingleDetails;
