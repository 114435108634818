import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";

function Slider({ name, description }) {
  const { data, isLoading } = useFETCH(
    `get_home_page_slider_images?local=${localStorage.getItem("language")}`
  );
  
  return (
    <div className="relative">
      {isLoading ? (
        <>
          <Col>
            <div className="loadingPro rounded-md overflow-hidden w-full h-[70vh]">
              <div className="h-full w-full"></div>
            </div>
          </Col>
        </>
      ) : (
        ""
      )}
      <div className="relative">
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="mySwiper h-[70vh] overflow-hidden"
          style={{ direction: "ltr" }}
        >
          {data?.data.data.map((e) => (
            <SwiperSlide key={e.id}>
              <img src={fileUrl + e.image} alt="" className="w-full" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-gray-700 py-2 px-4 bg-white/60 rounded-2xl max-sm:w-[90%] max-sm:text-sm shadow-lg">
        {name && <h2 className="font-bold text-2xl">{name}</h2>}
        {description && (
          <p className="font-semibold text-lg mt-5">{description}</p>
        )}
      </div>
    </div>
  );
}
export default Slider;
