import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../Translate/ContextProvider";
import {
  Button,
  ContactUsCom,
  Slider,
  Title,
  WorkSection,
} from "../../components/Index";

const Home = () => {
  const { content, dataAll } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `get_work_sections?local=${localStorage.getItem("language")}`
  );
  console.log(dataAll);
  return (
    <>
      <Slider
        description={dataAll?.["النص المعروض فوق سلايدر الصفحة الرئيسية"]}
      />
      {isLoading ? <Loading /> : ""}
      <Container>
        <Title name={content.aboutUs} link="about-us" />
        <div className="flex max-md:flex-col justify-between border-2 border-Main rounded-xl">
          <Col md={9} className="">
            <p className="flex-1 text-lg mb-8 py-5 px-7">
              {dataAll?.["نص من نحن في الصفحة الرئيسية "]}
            </p>
            <Col sm={6} className="mx-auto">
              <Button name={content.Read} />
            </Col>
          </Col>
          <Col md={3}>
            <img
              src={fileUrl + dataAll?.["صورة من نحن في الصفحة الرئيسية"]}
              alt=""
              className="w-full h-[200px] rounded-lg  max-md:w-[300px] mx-auto"
            />
          </Col>
        </div>
        <Title name={content.ourWork} link="our-work" />
        <Row>
          {data?.data.data.map((e) => (
            <Col lg={3} xs={6}>
              <WorkSection
                key={e.id}
                img={fileUrl + e.section_image}
                name={e.section_name}
                description={e.section_description}
                link={`/our-work/${e.section_id}`}
              />
            </Col>
          ))}
        </Row>
        <Title name={content.ContactUs} link="contact-us" />
        <ContactUsCom />
      </Container>
    </>
  );
};

export default Home;
