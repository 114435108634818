import { fileUrl } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextProvider";
import { Slider } from "../../components/Index";

function AboutUs() {
  const { content, dataAll } = useContextTranslate();
  return (
    <>
      <Slider
        name={content.aboutUs}
        description={dataAll?.["نص من نحن في صفحة من نحن"]}
      />
      <Container>
        <h1 className="text-xl text-center font-bold text-Main mt-4 mb-2">
          {content.aboutUs}
        </h1>
        <p className="text-lg leading-9 mb-8">
          {dataAll?.["نص من نكون في صفحة من نحن"]}
        </p>
        <div className="border-t-2 border-Secondary w-[80%] mx-auto my-9" />
        <Row className="items-center">
          <Col md={3}>
            <img
              src={
                fileUrl +
                dataAll?.["الصورة المعروضة بجانب أهدافنا في صفحة من نحن"]
              }
              alt=""
              className="w-full h-[200px] rounded-lg max-md:w-[300px] mx-auto"
            />
          </Col>
          <Col md={9}>
            <h1 className="text-xl font-bold text-Main mx-7 mt-5">
              {content.OurGoals}
            </h1>
            <p className="flex-1 text-lg mb-8 py-5 px-7 leading-9">
              {dataAll?.["نص أهدافنا في صفحة من نحن"]}
            </p>
          </Col>
        </Row>
        <div className="border-t-2 border-Secondary w-[50%] mx-auto my-9" />
        <Row className="items-center">
          <Col md={9}>
            <h1 className="text-xl font-bold text-Main mx-7 mt-5">
              {content.OurVision}
            </h1>
            <p className="flex-1 text-lg mb-8 py-5 px-7 leading-9">
              {dataAll?.["نص رؤيتنا في صفحة من نحن"]}
            </p>
          </Col>
          <Col md={3}>
            <img
              src={
                fileUrl +
                dataAll?.["الصورة المعروضة بجانب رؤيتنا في صفحة من نحن"]
              }
              alt=""
              className="w-full h-[200px] rounded-lg  max-md:w-[300px] mx-auto"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default AboutUs;
