import { Container } from "../../Tools/Grid-system";
import { ContactUsCom } from "../../components/Index";

function ContactUs() {
  return (
    <>
      <Container>
        <ContactUsCom />
      </Container>
    </>
  );
}
export default ContactUs;
