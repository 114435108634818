import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Footer, Navbar } from "./Layout/Index";
import { AboutUs, ContactUs, Home, OurWork, SingleDetails, WorkDetails } from "./pages/Index";
const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div style={{ transition: "0.3s" }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="our-work">
          <Route index element={<OurWork />} />
          <Route path=":id" element={<WorkDetails />} />
          <Route path="details/:id" element={<SingleDetails />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
