import { useState } from "react";
import { MdDensityMedium } from "react-icons/md";
import Logo from "../../images/logo.png";
import { NavLink } from "react-router-dom";
import Translation from "../../Translate/Translation";
import { useContextTranslate } from "../../Translate/ContextProvider";
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { content } = useContextTranslate();
  const navbar = [
    { name: content.home, link: "/" },
    { name: content.aboutUs, link: "/about-us" },
    { name: content.ourWork, link: "/our-work" },
    { name: content.ContactUs, link: "/contact-us" },
  ];
  return (
    <>
      <div
        className={`sticky z-40 top-0 text-black left-0 w-full flex justify-between items-center shadow-sm border-b-4 shadow-white bg-white py-1 md:px-3`}
      >
        <div className="max-md:order-2">
          <img src={Logo} className="w-20 bg-white mx-5" alt="logo" />
        </div>

        <MdDensityMedium
          className="md:hidden max-md:mx-10 max-md:order-1"
          size={25}
          onClick={() => setToggleMenu(!toggleMenu)}
        />
        <ul
          style={{ transition: " 0.3s" }}
          className={`justify-around items-center flex-1 md:flex max-md:mx-5 ${
            toggleMenu
              ? "flex-col opacity-100 absolute top-16 shadow-md rounded-3xl text-center bg-white py-4 px-5 space-y-7"
              : "max-md:opacity-0 hidden max-md:-z-50 max-md:translate-y-16 max-md:flex-col max-md:top-20 max-md:shadow-md max-md:rounded-3xl max-md:text-center max-md:bg-white max-md:py-4 max-md:px-5 max-md:space-y-7"
          }`}
        >
          {navbar.map((e) => (
            <li className="text-lg font-semibold">
              <NavLink to={e.link}>{e.name}</NavLink>
            </li>
          ))}
        </ul>
        <div className="mx-10 max-md:order-3">
          <Translation />
        </div>
      </div>
    </>
  );
};

export default Navbar;
