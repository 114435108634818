import { Link } from "react-router-dom";

function Button({ link, name, className }) {
  return (
    <Link to={link || ""}>
      <div
        className={`border-2 border-Main text-Main text-center py-3 px-5 rounded-lg hover:bg-Main hover:text-white transition-all w-full ${className}`}
      >
        {name}
      </div>
    </Link>
  );
}
export default Button;
