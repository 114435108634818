import logo from "../../images/logo.png";
import { FaLinkedin, FaFacebook } from "react-icons/fa";
import { LuInstagram } from "react-icons/lu";
import { useContextTranslate } from "../../Translate/ContextProvider";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { content, dataAll } = useContextTranslate();
  const navbar = [
    { name: content.home, link: "/" },
    { name: content.aboutUs, link: "/about-us" },
    { name: content.ourWork, link: "/our-work" },
    { name: content.ContactUs, link: "/contact-us" },
  ];
  return (
    <>
      <div className="footer border-t-4 shadow-xl">
        <Container>
          <Row className="items-center">
            <Col xs={4} className="justify-center items-center">
              <img
                src={logo}
                className="lg:w-56 md:w-40 sm:-mb-9 w-36 mx-auto mb-2 rounded-lg"
                alt=""
              />
            </Col>
            <Col xs={4} className="justify-center items-center">
              <div className="flex items-center justify-center gap-4">
                <a href={dataAll?.["رابط الفيس بوك"]} target="_blank">
                  <FaFacebook
                    size={30}
                    color="#0573A0"
                    className="cursor-pointer"
                  />
                </a>
                <a href={dataAll?.["رابط الانستا غرام"]} target="_blank">
                  <LuInstagram
                    size={30}
                    color="#CA367A"
                    className="cursor-pointer"
                  />
                </a>
                <a href={dataAll?.["رابط لينكد ان"]} target="_blank">
                  <FaLinkedin
                    size={30}
                    color="#0D25C6"
                    className="cursor-pointer"
                  />
                </a>
              </div>
            </Col>
            <Col xs={4} className="justify-center items-center sm:-mb-9">
              {navbar.map((e) => (
                <li className="list-none text-xl font-semibold text-center mb-2">
                  <NavLink to={e.link}>{e.name}</NavLink>
                </li>
              ))}
            </Col>
          </Row>
        </Container>
        <div className="text-lg flex flex-col justify-center gap-1 mx-auto text-center max-[400px]:text-sm titleNave">
          <p>
            {localStorage.getItem("language") === "ar"
              ? `@  ${new Date().getFullYear()} جميع حقوق النشر محفوظة لشركة ICR`
              : `All Rights Reserved For ICR company @ ${new Date().getFullYear()}`}
          </p>
          <a href="mailto:IdeaCodeReality.ICR@gmail.com" className="mb-1">
            {localStorage.getItem("language") === "ar"
              ? ` للتواصل : IdeaCodeReality.ICR@gmail.com`
              : `Contact Us: IdeaCodeReality.ICR@gmail.com`}
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
